<template>
  <div id="faq_page">
    <div class="container">
      <span class="page-title">Frequently Asked Questions</span>

      <div class="faq-category-container" v-for="(category, index) in faq" :key="index">
        <span class="category-name">{{ category.category_name }}</span>
        <el-collapse v-model="category.active" accordion>
          <el-collapse-item v-for="(question, i) in category.questions" :key="i" :name="i" :title="question.question">
            <div>{{ question.answer }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>

      
    </div>
  </div>
</template>

<script>
import firebase from "@/firebase";

export default {
  data: () => ({
    faq: []
  }),

  mounted() {
    firebase.storage().ref('System/FAQ/employee-app.json').getDownloadURL().then(url => {
      console.log(url);

      this.SharoAPI({ action: 'corsAnywhere', data: url }).then(result => {
        console.log(result);
        
        if (result.data.error) {
          return this.$message({ type: 'error', message: "Internal error: cannot get FAQ", showClose: true });
        }

        this.faq = result.data.result;
      });
    }).catch(err => {
      console.error(err);
      this.$message({ type: 'error', message: "Internal error: cannot get FAQ", showClose: true });
    });
  }
}
</script>

<style lang="scss" scoped>
  #faq_page > .container {
    max-width: 600px;
  }

  .category-name {
    color: #77c5ff;
    font-size: 16px;
    font-weight: 500;
    margin-top: 7px;
    margin-bottom: 10px;
    display: block;

    &:not(:first-of-type) {
      margin-top: 25px;
    }
  }

  .question-container {
    width: 100%;
    border: 1px solid rgba(0,0,0,0.1);
    min-height: 60px;
    border-radius: 5px;
    margin-bottom: 5px;
  }
</style>