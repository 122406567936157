var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "faq_page" } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("span", { staticClass: "page-title" }, [
          _vm._v("Frequently Asked Questions")
        ]),
        _vm._l(_vm.faq, function(category, index) {
          return _c(
            "div",
            { key: index, staticClass: "faq-category-container" },
            [
              _c("span", { staticClass: "category-name" }, [
                _vm._v(_vm._s(category.category_name))
              ]),
              _c(
                "el-collapse",
                {
                  attrs: { accordion: "" },
                  model: {
                    value: category.active,
                    callback: function($$v) {
                      _vm.$set(category, "active", $$v)
                    },
                    expression: "category.active"
                  }
                },
                _vm._l(category.questions, function(question, i) {
                  return _c(
                    "el-collapse-item",
                    { key: i, attrs: { name: i, title: question.question } },
                    [_c("div", [_vm._v(_vm._s(question.answer))])]
                  )
                }),
                1
              )
            ],
            1
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }